.heatmap-support {
  overflow-x: auto;
  overflow-y: hidden;

  &__total {
    height: 176px;

    &__panel {
      min-height: 176px;
    }

    &__title {
      padding: 12px 0 0 22px;
    }

    &__value-container {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
    }

    &__value {
      font-size: 60px;
      text-align: center;
      line-height: 80px;
      padding-top: get-space(3);
      font-weight: 800;
      justify-content: center;
      align-items: center;
    }
  }

  &__levels {
    height: 176px;


    &__panel {
      height: 176px;
    }


    &__title {
      padding: 12px 0 0 22px;
    }

    &__pie-container {
      width: 100%;
      height: 112px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: absolute;
      left: -10%;
      bottom: 23px;
    }
  }

  &__confidence {
    height: 176px;

    &__panel {
      height: 176px;
    }

    &__title {
      padding: 12px 0 0 22px;
    }

    &__flex-container {
      display: flex;
      align-items: center;
    }

    &__score {
      display: flex;
      padding-left: 87px;
      padding-bottom: 40px;

      &__number {
        line-height: 70px;
        padding-top: get-space(3);
        font-size: 60px;
        font-weight: 800;
      }

      &__percent {
        position: relative;
        top: 10px;
        padding-top: get-space(3);
        line-height: 70px;
        font-size: 35px;
      }
    }

    &__pie {
      width: 520px;
      height: 112px;
      position: absolute;
      display: flex;
      justify-content: flex-start;
      left: 10%;
      bottom: 23px;
    }
  }
}