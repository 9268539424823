.organisation-dashboard {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      margin-bottom: 0;
    }

    &__filters {
      list-style-type: none;
      margin: 0;
      padding: 0;
      color: $gray-300;

      &--active {
        color: #14bdc1;
      }
    }

    &__filters:hover {
      cursor: pointer;
    }

    &__controls {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    &__divider {
      display: flex;
      align-items: center;
      padding: 0 18px;
    }

    &__start-date-text {
      padding-right: 15px;
    }

    &__dropdown {
      width: 120px;
      padding-left: 4px;

      p {
        font-size: 14px;
        line-height: 16px;
      }
    }

    h2 {
      margin-bottom: 0;
    }
  }

  &__filters {
    list-style-type: none;
    margin: 0;
    padding: 0;
    color: $gray-300;

    &--active {
      color: #14bdc1;
    }
  }

  &__filters:hover {
    cursor: pointer;
  }

  &__controls {
    display: flex;
    align-items: center;
  }

  &__divider {
    display: flex;
    align-items: center;
    padding: 0 18px;
  }

  &__start-date-text {
    padding-right: 15px;
  }

  &__dropdown {
    height: 20px;
    width: 120px;
    padding-left: 4px;
    margin-top: -16px;

    p {
      font-size: 14px;
      line-height: 16px;
    }
  }

  p {
    margin: 0;
    color: $gray-300;
  }

  &__sunburst-legend {
    width: 300px;
  }
}

.colour-scale {
  height: 12px;
  border: 1px solid #dedede;
  border-radius: 24px;
}

.sunburst-dashboard {
  margin: 0 auto;
}
