.timeline-milestone {
  position: absolute;
  top: 0%;
  transform: translateY(-50%) rotate(45deg) !important;
}

.milestone {
  transform: rotate(45deg);
  width: 14px;
  height: 14px;
  transform-origin: center;
  background-color: $gray-400;

  &--grouped {
    border-radius: 50%;
    width: 20px !important;
    height: 20px !important;
    background-color: #303f95;
    transform: translateY(-50%) rotate(0deg) !important;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.timeline-marker__icon,
.milestone {
  &--goLive {
    background-color: #1ac541 !important;
  }
  &--comms {
    background-color: #f7820d !important;
  }
  &--orgAnnouncement {
    background-color: #c40000 !important;
  }
  &--changePlan {
    background-color: #43199d !important;
  }
  &--businessReadiness {
    background-color: #f2ce56 !important;
  }
  &--training {
    background-color: #2e932e !important;
  }
  &--engagementActivity {
    background-color: #561ac5 !important;
  }
  &--uat {
    background-color: #1ac5b4 !important;
  }
  &--designConfiguration {
    background-color: #b1c51a !important;
  }
  &--dataMT {
    background-color: #1a8fc5 !important;
  }
  &--hypercare {
    background-color: #1a25c5 !important;
  }
  &--stageGate {
    background-color: #bf1ac5 !important;
  }
  &--other {
    background-color: #393939 !important;
  }
}

.org-timeline__legend {
  p {
    font-size: 11px;
  }
}
.org-timeline__legend-item .milestone {
  width: 8px;
  height: 8px;
}
