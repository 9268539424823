.processes-settings {
  height: 100%;
  .tabs__content {
    height: calc(100% - 58px);
  }

  .tab-panel {
    height: 100%;
  }
}
