.mega-menu {
  background-color: $black;
}

.organisation-mega-menu {
  display: flex;
  flex-wrap: wrap;

  &__item {
    display: inline-block;
    width: 290px;
  }
}
