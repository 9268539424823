.dashboard {
  .ag-theme-alpine {
    height: 500px !important;
  }
}

.scrollableModal {
  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__buttons {
  }

  &__content {
    overflow-y: scroll;
    max-height: 500px;
  }
}
