.project-overview__side-panel {
    &__overview-description > p, &__objectives-text > p {
      margin-bottom: 0;
    }
  
    &__objectives-text > ul {
      margin: 0;
    }
  }

  .project-overview__main-panel {
    &__timeline {
      position: relative;

      .timeline__placeholder {
        height: 240px;
        width: 100%;
      }

    }

    &__timeline > .timeline {
      max-width: none!important;
      position: unset!important;

      .react-calendar-timeline {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
      }

      .timeline__controls {
        top: 250px! important;
      }
    }
  }

  
