.stakeholder-grid {
  &__cell {
    &--unknown {
      background-color: $gray-200;
      color: $black;
    }
    &--low {
      background-color: theme-color("secondary");
      color: $white;
    }
    &--medium {
      background-color: theme-color("secondary-dark");
      color: $white;
    }
    &--high {
      background-color: theme-color("secondary-dark-1");
      color: $white;
    }

    &--poor {
      background-color: #f91e1e;
      // color: $white;
    }

    &--neutral {
      background-color: #f9ab1e;
      // color: $white;
    }

    &--good {
      background-color: #a6dc33;
      // color: $white;
    }

    &--very-good {
      background-color: #1ac541;
      // color: $white;
    }
  }
}

.stakeholder-add-input {
  width: 330px;
  & input {
    height: get-space(5);
    margin-top: get-space(2);
  }
}

.stakeholder-profiling-cell {
  display: flex;
  align-items: center;
}
