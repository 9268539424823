#UsersTable {
    .divTableHead,
    .divTableCell {
        &--permission-scope,
        &--status {
            width: 200px;
            flex: none !important;
        }

        &--first-name-lastname {
            width: 300px;
            flex: none !important;
        }
    }
}
