.insight-tab-content {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__space-filler {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
}
