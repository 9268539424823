$settings-view-actions-padding-y: get-space(5);

.settings-view {
    height: 100%;
}

.settings-view__actions {
    @include border-top();
    padding: $settings-view-actions-padding-y 0;
}

.stakeholder-settings{
    height: 100%;
    
    .tabs {
        height: 100%;

        .tabs__content {
            height: 100%;

            .tab-panel {
                height: 100%;
            }
        }
    } 
}

.organisation-stakeholders {
    width: 100%;
    height: 100%;

    .tabs {
        height: 100%;

        .tabs__content {
            height: 100%;

            .tab-panel {
                height: 100%;
            }
        }
    }

    &--upload {
        width: 100%;
        height: 100%;

        &--file-drop {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: center;            
            align-content: normal;
            height: 100%;
            padding-top:32px;

            .file-drop-target{
                width: 100%;

                &.file-drop-dragging-over-target{
                    background-color: lightblue;
                }
            }

            &--graphic {
                text-align: center;
                display: block;
                flex-grow: 0;
                flex-shrink: 1;
                flex-basis: auto;
                align-self: auto;
                order: 0;
            }
        }

        &--file-list {
            height: 100%;
            background-color: #f8f8f8;

            &--file-card {

                &--content{
                    display: flex;
                    flex-direction: row;

                    .icon{
                        display: block;
                        flex-grow: 0;
                        flex-shrink: 1;
                        flex-basis: auto;
                        align-self: center;
                        order: 0;
                    }

                    &--text{
                        display: block;
                        flex-grow: 0;
                        flex-shrink: 1;
                        flex-basis: auto;
                        align-self: auto;
                        order: 0;

&--heading{
    font-weight: bold;
}

                        &--subheading{
font-size: 11px;
                        }
                    }
                }

                cursor: pointer;

                &.selected {
                    background-color: lightblue !important;
                    border: 1px solid #dedede !important;
                }

                &--file-name {}

                &--uploaded-by {
                    font-size: 11px;
                }

                &--progress-wrapper {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: center;
                    align-items: center;
                    align-content: normal;

                    &--uploaded-status {

                        display: block;
                        flex-grow: 1;
                        flex-shrink: 1;
                        flex-basis: auto;
                        align-self: auto;
                        order: 0;

                        .pill {
                            font-size: 11px !important;
                        }
                    }

                    &--progress-bar {
                        width: 50%;
                        display: block;
                        flex-grow: 1;
                        flex-shrink: 1;
                        flex-basis: auto;
                        align-self: auto;
                        order: 0;
                    }
                }


            }
        }
    }
}