.programme-overview {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  &__expanded {
    flex: 1 1 0%;
  }

  &__progress {
    display: flex;
    flex-direction: row;
    margin-bottom: get-space(4);
    width: 100%;
    justify-content: space-between;
  }
}
