.action-reporting {
  &__metric-pie {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}

.reporting-title {
  h3 {
    @extend %strong;
    text-transform: uppercase;
    white-space: nowrap;
  }
}

.action-timeline {
  .react-calendar-timeline {
    max-height: 100% !important;
  }

  .timeline {
    max-width: 100vw !important;
  }

  .rct-sidebarHeader {
    display: flex;
    background-color: #f7f7f7 !important;
    border-bottom: 2px solid $white;
    border-right: 2px solid $white;
    align-items: flex-end;
  }

  &__action-type:hover {
    cursor: pointer;
  }

  &__active-type {
    @extend %strong;
    color: $viking;
  }

  .rct-dateHeader {
    color: $gray-400;
  }

  .rct-dateHeader-primary {
    color: $black;
  }

  .rct-sidebar-row {
    border-bottom: 2px solid $white !important;
  }

  .rct-hl-even,
  .rct-hl-odd {
    background-color: $gray-100 !important;
    opacity: 1;
  }
}

.action-reporting-checkbox {
  &__complete {
    color: $viking !important;
  }

  &__in-progress {
    color: map-get(map-get($visualisation-colors, "yellow"), "shade-3") !important;
  }

  &__not-started {
    color: map-get(map-get($visualisation-colors, "purple"), "shade-1") !important;
  }

  &__overdue {
    color: $red !important;
  }
}

.action-reporting-timeline-bar {
  background: repeating-linear-gradient(45deg, #606dbc, #606dbc 10px, #465298 10px, #465298 20px);
}
