.business-process-uploader {
  padding-top: 2rem;
  &__drop-area {
    height: 400px;
    width: 100%;
    border: 4px dashed lightblue;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__progress {
    height: 400px;
  }

  .file-drop-target {
    transition: background-color 600ms cubic-bezier(0.075, 0.82, 0.165, 1);
    &.file-drop-dragging-over-target {
      background-color: lightblue;
    }
  }
}
