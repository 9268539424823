#StakeholderTableSettings {
    .divTableHead,
    .divTableCell {
        &--business-area-name,
        &--role-name {
            width: 300px;
            flex: none !important;
        }

        &--first-name-lastname {
            width: 300px;
            flex: none !important;
        }
    }
}
