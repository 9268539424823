pre {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

code {
  font-family: Courier, "New Courier", monospace;
  font-size: 12px;
}
