.count-display {
  height: 210px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f7f7f7;

  &--disabled {
    opacity: 0.6;
  }
}

.count-display-frame {
  display: flex;
  flex-direction: row;
  background-color: #f7f7f7;
  width: -moz-fit-content;
  width: fit-content;
  &__block {
    &--left {
      width: 256px;
    }
    &--right {
      padding: get-space(2) get-space(3);
      display: flex;
      flex-direction: row;
      width: 256px;
      align-items: center;
      justify-content: center;
    }
  }
}

.image-display {
  & + & {
    margin-left: 8px;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 230px;
  height: 100%;
  background-color: #e5e5e5;

  &--disabled {
    opacity: 0.4;
    pointer-events: none;
  }
}
