.stakeholder-heatmap {
  &__headings {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__cell {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__tooltip{
    background-color: $white;
    padding: 1em;
  }
}

.stakeholder-reporting {
  &__toggle {
    &--active {
      color: $viking;
    }
  }
}
