.top-navigation {
  position: relative;

  &__mega-menu {
    position: relative;
    width: 100%;
  }
}

.navigation-view {
  &__chat {
    margin-top: 20px !important;
  }

  &__avatar {
    margin-top: 11px !important;
  }

  &__search {
    &::after {
      content: "";
      position: absolute;
      width: 1px;
      height: 52px;
      right: 0px;
      top: 50%;
      transform: translateY(-50%);
      background-color: $gray-400;
    }
  }
}

.card-columns {
  max-height: 570px;
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-gap: 1.25rem;
  -moz-column-gap: 1.25rem;
  column-gap: 1.25rem;
}
