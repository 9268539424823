.data-extended-view {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  &__block {
    &--wrapper {
      display: flex;
      flex-direction: row;
      flex: 1;
    }
  }

  &__col {
    flex: 1;
    height: 100%;
    z-index: 11;

    &--tabs {
      z-index: 1;
    }
  }

  &__tabs {
    .tab-group__tab {
      background-color: $white;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
