.action-list-view {
  .smart-table-cell--rag-status {
    flex: none;
    width: 80px;
  }
}

.impacts-view {
  .smart-table-cell--ref-number {
    white-space: nowrap;
  }
}

