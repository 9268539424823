.project-main-panel {
  &__labels {
    display: flex;
  }
  &__block {
    &--left {
      width: 256px;
    }

    &--right {
      padding-left: get-space(3);
    }
  }
}
