.navigation-view {
  position: relative;

  &__label-tooltip {
    position: absolute;
    top: 100%;
    right: get-space(1);
    margin-top: get-space(3);
    z-index: 9;
    display: none;
  }

  &__tooltip-content {
    position: absolute;
    top: 100%;
    right: get-space(1);
    margin-top: get-space(3);
    z-index: 99999;
    color: $black;
    text-align: center;
  }

  &:hover {
    .navigation-view__label-tooltip {
      display: block;
    }
  }
}

.navigation-view--display-tooltip {
  .navigation-view__label-tooltip {
    display: none !important;
  }
}