.overview {
    display: flex;
    flex-direction: row;
    min-height: 100%;
  
    &__main-panel {
      min-height: 100vh;
      flex: 1;
    }
  
    &__side-panel {
      min-height: 100vh;
      background-color: $gray-100;
      width: 500px;
    }
  }
  