.navigation-settings {
  display: flex;
  align-items: flex-end;
  height: 100%;
  position: relative;
  bottom: 10px;

  &__icon--active {
    color: $primary;
  }

  &__action {
    color: $white;
    display: block;
    @include border-radius(4px);
  }


  &__label-tooltip {
    position: absolute;
    left: 100%;
    bottom: get-space(1);
    margin-left: get-space(3);
    z-index: 99999;
    display: none;
  }

  &__tooltip-content {
    position: absolute;
    left: 100%;
    bottom: get-space(1);
    margin-left: get-space(3);
    z-index: 99999;
    text-align: center;
  }

  &:hover {
    .navigation-settings__label-tooltip {
      display: block;
    }
  }
}

.navigation-settings--display-tooltip {
  .navigation-settings__label-tooltip {
    display: none !important;
  }
}