@import "./components/BenefitsPanel/project-main-panel";

.project-overview__timeline {
  max-width: 100% !important;

  .rct-item {
    &:hover {
      cursor: default !important;
    }
  }
}
