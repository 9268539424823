@import "../../../../node_modules/@flightpath/coreui/dist/sass/coreui.scss";

.duration-editor-tooltip {
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translateY(50%);
  background-color: $astral;
  border: 1px solid $primary;
  padding: 8px 12px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  min-width: 200px;
  min-height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000;
  &::after {
    content: "";
    position: absolute;
    top: -6px;
    left: 50%;
    transform: translateX(-50%);
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid $astral;
  }
}

.duration-editor {
  &__label {
    text-align: center;
    margin-bottom: 10px;
    font-size: 1em;
    color: $white;
    font-weight: bold;
  }

  &__input-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__input {
    width: 50px;
    text-align: center;
    box-sizing: border-box;
  }

  &__colon {
    margin: 0 4px;
    color: $white;
  }
}
