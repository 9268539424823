
.activity-card .collapsed {
    display: -webkit-box;
    max-height: 68px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.activity-card .showText {
    font-weight: 600;
    color: $purple;
    text-decoration: underline;
    padding-top: 8px;
    cursor: pointer;
}

.activity-card p {
    margin-block-end: 0;
}