.benefit-tracker {
  &__value {
    font-weight: 600;
    color: #3f4041;

    &--small {
      font-size: 1.7rem;
      height: 100% !important;
      line-height: 1.3;
    }
  }

  &__panel--secondary {
    background-color: #e5e5e5;
    color: #3f4041;
  }
}
