.project-mega-menu {
  &__list > div > ul {
    width: 100%;
    padding: 0px;
    margin: 0px;
  }

  &__projects {
    &--split {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .project-mega-menu__item {
        flex: 50%;
        max-width: 50%;

        &:nth-child(odd) {
          padding-right: get-space(2) !important;
        }

        &:nth-child(even) {
          padding-left: get-space(2) !important;
        }
      }
    }
  }

  &__search input {
    box-shadow: 0 1px 0 0 $white !important;
    color: $white !important;
  }

  &__search .icon {
    color: $white !important;
  }

  &__wrapper {
    &--single {
      width: 32.9%;
      padding: 0 get-space(2) get-space(3);
    }
    &--double {
      width: 65.9%;
      padding: 0 get-space(2) get-space(3);
    }
  }

  &__list {
    &__item {
      display: inline-block;

      &--active {
        p {
          color: $viking !important;
        }
      }
    }
  }
}
