.micro-form {
display: flex;

 .input {
     width: 400px;
     height: 30px;
 }

 .form-field {
     margin-bottom: 0;
 }
}