.filter-pills {
    display: flex;
    flex-flow: wrap;

    &__reset-button {
        min-width: 150px;
        margin-top: get-space(1);
    }

    &__pill-display {
        flex: 1;
    }
}
