.programme-locations{
    &--programme-location-pill{
        margin: 0 0 8px 0;

        h4{
            margin: 0;
        }
        &--source{
            font-weight: normal;
            font-size: 11px;
            clear: both;
        }

        &--project-list{
            list-style-type: none;
            margin: 0 0 0 8px;
            padding: 0;

            &--item{
                margin: 0 8px 0 0;
                padding: 0;
                float: left;
                font-size: 11px;
            }
        }
    }

    &--programme-location{
        clear: both;
        margin: 0 0 8px 0;

        h4{
            margin: 0;
        }
        &--source{
            font-weight: normal;
            color: grey;
            font-size: 11px;
            clear: both;
        }

        &--project-list{
            list-style-type: none;
            margin: 0 0 0 8px;
            padding: 0;

            &--item{
                margin: 0 8px 0 0;
                padding: 0;
                font-size: 11px;
            }
        }

        &--no-projects{
            color: gray;
            font-size: 11px;
        }
    }
}

.project-info-card{
    .avatar-content{
        float:left;
        margin-right: -25px;
    }

    .metric-card{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: normal;
        align-items: center;
        align-content: stretch;

        &--data{
            display: block;
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: auto;
            align-self: auto;
            order: 0;

            h2{
                margin: 0;
                padding: 0;
            }

            &--count{
                font-size: 24px;
            }
        }

        &--chart{
            display: block;
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: auto;
            align-self: auto;
            order: 0;
            text-align: center;
        }
    }

    .project-team{
        h4{
            margin: 0;
            padding: 0;
            font-size: 13px;
        }
    }
}