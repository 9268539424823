.wrapper {
  height: 700px;
  position: relative;
}
.graphContainer {
  height: 700px;
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: 5;
}