.heatmap {
  &__cell--totals {
    p {
      display: none;
    }
  }

  &__sidebar {
    margin-top: 1px;
  }

  &__container {
    &--background {
      background-color: #f8f8f8;
    }

    &--background {
      overflow: auto;
      max-width: 100%;
      white-space: nowrap;
    }

    &--content-flex-container {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    &--content {
      justify-content: center;
      overflow: auto;
    }
  }

  &__header-row {
    display: flex;
    justify-content: space-between;
    padding: 30px 40px;
  }

  &__checkbox {
    display: flex;
    justify-content: space-between;
    width: 105px;
  }
  p {
    margin: 0;
  }
}
