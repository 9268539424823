.org-timeline {
  &__legend {
    display: flex;
    justify-content: flex-end;
  }

  &__legend-item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: get-space(3);
  }
}
