.organisation-switch {
  @include border-y($navigation-border-default);

  &__label {
    color: $navigation-text-color-primary;
  }

  &__action {
    @include user-select(none);
    color: $navigation-text-color-secondary;
    &:hover {
      cursor: pointer;
    }
  }
}
