@import "./navigation/navigation";
@import "./overview/overview";
@import "./stakeholders/stakeholders";
@import "./projects/action-reporting";
@import "./stakeholders/stakeholder-reporting";
@import "./projects/count-display";
@import "./projects/project-main-panel";
@import "./programmes/programmeDashboard";
@import "../../../pages/insight/pages";
@import "./dashboard/dashboard";

.vertical-line {
  width: 0px;
  margin-top: 6px;
  margin-right: 8px;
  height: 32px;
  border: 1px solid #ccc;
}
