@import "../../../../../../../../node_modules/@flightpath/coreui/dist/sass/coreui.scss";

@mixin activeBorder($border-color: $primary, $border-width: 1px 1px 1px 1px) {
  border-color: $border-color;
  border-style: solid;
  border-width: $border-width;
}

.phases-settings {
  .ag-row-level-1 {
    overflow: visible;
  }

  .ag-overlay {
    pointer-events: all;
  }
}

.pulse-detail-row-content {
  @include activeBorder($border-width: 0px 0px 0px 2px);
}

.detail-row-master {
  &--expanded {
    @include activeBorder($border-width: 0px 0px 4px 0px);
    background: rgba($color: $primary, $alpha: 0.2);
  }
}

.col-header {
  &--highlighted {
    @include activeBorder($border-width: 0px 1px 4px 1px);
  }
}
