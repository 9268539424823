.organisation-nav-list {
    padding: get-space(4) get-space(3) get-space(4) get-space(3);
    &__item {
        color: $navigation-text-color-primary;
        // padding: get-space(2);
        display: block;
        padding: get-space(2) get-space(3);
        @include border-radius(4px);
        @include transition(background-color 300ms ease-in);

        &:hover,
        &--active {
            cursor: pointer;
            background-color: $navigation-bg-color-primary;
        }
    }
}
